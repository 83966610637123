html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F2F3F4; */
  background-color: #dadcdf;
  height: 100%;
  width: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
select,
option {
  padding: 6px 2px;
}

/* overall app styling */

button:disabled,
button[disabled] {
  border-color: transparent;
  cursor: initial;
}

button[disabled]:hover {
  border-color: transparent;
  background-color: transparent;
  color: #808b94;
  cursor: initial;
}

/* material table styles */

.MuiTable-root tr:not(:first-child):hover {
  filter: brightness(0.925);
}

/* FORMIK REACT-SELECT STYLES */

#root
  > div
  > main
  > form
  > div.RecordPanel_fieldsContainer__aoaiV
  > label:nth-child(3)
  > div.RecordPanel_selectBoxError__3mDhj.css-2b097c-container
  > div:nth-child(2) {
  display: none;
}

#territories > div:nth-child(2) {
  display: none;
}
/* 
input[name="password"] {
  -webkit-text-security: disc !important;
} */
