.formPanel {
  position: absolute;
  border-left: 1px solid lightgray;
  border: 1px solid lightgray;
  background-color: whitesmoke;
  box-sizing: border-box;
  width: 500px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: -50px 0 100px rgba(0, 0, 0, 0.2), -5px 0 15px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px 14px 20px;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 10px 8px 2px whitesmoke;
  z-index: 1;
}

.panelHeader > button {
  margin: 0 0 0 8px !important;
}

.confirmBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.confirmBox > button {
  height: 36px;
}

.buttonGroup {
  display: flex;
  gap: 20px;
}

.panelLabel {
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.fieldsContainer {
  display: "flex";
  flex-direction: column;
}

.inputList {
  padding: 12px 20px;
}

.bottomButtons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .formPanel {
    width: 100%;
  }
  .panelHeader {
    flex-wrap: wrap;
  }
  .panelLabel {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }
  .buttonGroup {
    flex: 1;
    justify-content: space-between;
  }
}

/* FORMIK STYLE */

.input {
  margin-top: 2px;
  padding: 8px;
  font-size: 16px;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: -webkit-fill-available;
}

.input:focus {
  border-color: #005da8;
  box-shadow: 0 0 0 3px #005ca83d;
  outline: none;
}

.input.error {
  border-color: red;
}

.label {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 0;
  /* text-transform: uppercase; */
  display: "flex";
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-between;
}

.fieldHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4px;
  gap: 8px;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.inputError {
  margin-top: 2px;
  padding: 8px;
  font-size: 16px;
  display: block;
  border-radius: 4px;
  border: 2px solid red;
  width: -webkit-fill-available;
}

.inputValid {
  margin-top: 2px;
  padding: 8px;
  font-size: 16px;
  display: block;
  border-radius: 4px;
  border: 2px solid rgb(12, 155, 24);
  width: -webkit-fill-available;
}

.selectBoxError > div {
  /* margin-top: 4px;
  padding: 6px 8px;
  font-size: 16px;
  display: block;
  border-radius: 4px; */
  border: 2px solid red;
}

.selectBoxValid > div {
  /* margin-top: 4px;
  padding: 6px 8px;
  font-size: 16px;
  display: block;
  border-radius: 4px; */
  border: 2px solid rgb(12, 155, 24);
}

/* .inputError:focus {
  border-color: darkred;
  box-shadow: 0 0 0 3px darkred;
  outline: none;
} */

.inputError.error {
  border-color: red;
}

.label {
  font-weight: bold;
  font-size: 13px;
  /* text-transform: uppercase; */
  display: block;
}

.inputError-feedback {
  color: red;
  margin-top: 0.25rem;
}

.errorText {
  color: red;
}

.check {
  color: green;
  padding-right: 8px;
}
