.app {
  padding: 16px 8px; /* horizontal padding affects the header rules for small devices !*/
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}

/* Buttons */

.btn {
}

.btnPrimary {
}

.BtnSecondary {
}

.btnSecondary {
  margin: 0 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 8px;
  border-radius: 4px;
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
  border: 2px solid #808b94;
}

.btnSecondary:hover {
  color: white;
  border: 2px solid #005da8;
  background-color: #005da8;
  padding: 4px 8px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .app {
    padding-bottom: 0;
  }
}


@media screen and (max-width: 620px) {
  .app {
    padding: 4px;
    padding-bottom: 0px;
  }
}

/* show black colored text if select field is disabled (easier to read) */
div[class$="-singleValue"] {
  color: black !important;
}
