.screen {
  background-color: black;
}

.dialog {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  /* z-index: 100000; */
  border: 1px solid lightgray;
  box-shadow: 0px 10px 20px 2px #00000047;
  padding: 40px;
  border-radius: 8px;
  width: 400px;
  max-height: "fit-content";
}

@media screen and (max-width: 600px) {
  .dialog {
    width: max-content;
  }
}

.dialog::backdrop {
  background: rgba(255, 0, 0, 0.25);
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.dialogTitle {
  margin: 0;
}

.dialogBody {
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
  gap: 20px;
}

/* .dialog:not(:fullscreen) {
  background: white;
} */