.primaryBtn {
  color: white;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  border-radius: 4px;
  border: 2px solid #808b94;
  background-color: #005da8;
  transition: color 0.1s ease-out, background-color 0.3s ease-out;
  padding: 4px 6px;
  cursor: pointer;
  max-height: 36px;
}

.primaryBtn:hover {
  color: white;
  border: 2px solid #003f72;
  background-color: #003f72;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.dangerBtn {
  /* color: white; */
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  border-radius: 4px;
  border: 2px solid #808b94;
  background-color: #8f0000;
  transition: color 0.1s ease-out, background-color 0.3s ease-out;
  padding: 4px 6px;
  cursor: pointer;
  max-height: 36px;
  color: white;
}

.dangerBtn:hover {
  color: white;
  border: 2px solid #6e0000;
  background-color: #6e0000;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.secondaryBtn {
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 6px;
  border-radius: 4px;
  transition: color 0.1s ease-out, background-color 0.3s ease-out;
  border: 2px solid #808b94;
  max-height: 36px;
}

.secondaryBtn:hover {
  color: white;
  border: 2px solid #005da8;
  background-color: #005da8;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.defaultBtn {
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 6px;
  border-radius: 4px;
  transition: color 0.1s ease-out, background-color 0.3s ease-out;
  border: 2px solid #808b94;
  max-height: 36px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.defaultBtn:hover {
  color: white;
  border: 2px solid #005da8;
  background-color: #005da8;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.input {
  margin: 8px;
  min-width: 250px;
  height: 38px;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 1rem;
}

.input:focus-visible {
  border: 1px solid transparent;
  outline: 2px solid #2684ff;
}

.input:disabled {
  background-color: #f1f1f1;
  color: black;
}

.input:hover:not(:disabled):not(:focus-visible) {
  border: 1px solid darkgray;
}
