.main {
  margin-top: 16px;
  padding-bottom: 16px;

  /* display: flex;
  flex: 1;
  flex-direction: row; */

  position: relative;
  overflow: auto;
  /* border: 1px solid rgb(192, 191, 191); */
  /* background: yellowgreen;
  border: 3px dotted red; */
}

.filtersBox {
  border: 1px solid lightgray;
  /* padding: 20px; */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 250px;
  /* height: 100%; */
  z-index: 10;
  padding: 8px 20px;
  margin-right: 16px;
  order: 0;
  box-sizing: border-box;
}

.filtersList {
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  flex-wrap: wrap;
}

.filtersList > label {
  /* min-width: 200px; */
  padding: 10px 8px 10px 0;
  height: auto;
}

.filtersList > select {
  width: 100%;
}

@media screen and (max-width: 1240px) {
  .main {
    flex-direction: column;
  }

  .filtersBox {
    margin-right: 0;
    width: 100%;
    height: 250px;
    display: flex;
    margin-bottom: 8px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filtersList {
    display: flex;
    margin-bottom: 8px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filtersList > label {
    /* min-width: 200px; */
    padding: 8px 12px 8px 0;
    height: auto;
  }
}
