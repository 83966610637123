.loginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.formContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  width: 500px;
  height: 500px;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 100px;
}

.label {
  margin-bottom: 16px;
}

.labelText {
  margin: 0;
  text-align: left;
  margin-left: 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .formContainer {
    width: 95%;
  }
}
