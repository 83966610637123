:root {
  --krohne_gray: #808b94;
}

.header {
  width: 100%;
  display: flex;
  /* horizontal gap affects the header rules for small devices !*/
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  /* background-color: lightblue; */
  /* padding: 20px 0; */
  /* height: 5vh; */
  /* height: 64px; */
  /* background-color: orange; */
}

.section {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
}

.sectionLeft {
  /* background-color: yellow; */
  order: 1;
  padding: 2px 0;
  white-space: nowrap;
}

.sectionCenter {
  /* background-color: pink; */
  column-gap: 20px;
  justify-content: center;
  height: 36;
  box-sizing: content-box;
  /* padding: 0 20px; */
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  flex-wrap: nowrap;
}

.inputField {
  width: 415px;
  height: 38px;
}

.inputField_skeleton {
  width: 415px;
  animation: skeleton-loading 1s linear infinite alternate;
  height: 38px;
}

@keyframes skeleton-loading {
  0% {
    background-color: lightgray;
  }
  100% {
    background-color: rgb(192, 192, 192);
  }
}

.sectionRight {
  /* background-color: green; */
  align-items: center;
  /* width: 300px; */
}

.mobileMenuBtn {
  display: none;
}

.leftPart {
  margin-top: 0;
  display: flex;
  align-items: flex-start;
}

.triangle {
  height: 24px;
}

.logoContainer {
  margin-right: 30px;
  /* background-color: purple; */
}

.logo {
  justify-self: flex-start;
  margin: 0;
  font-weight: 700;
  letter-spacing: -0.75px;
  font-size: 24px;
  color: #808b94;
  text-decoration: none;
  position: relative;
  top: -3px;
}

.mainNav {
  display: flex;
  align-items: center;
  position: relative;
  /* background-color: blue; */
}

.currentView {
  display: none;
}

.menu {
  display: flex;
  /* flex-wrap: wrap; */
  justify-self: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  /* background-color: red; */
}

.menuItem {
  margin: 0 8px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px 8px;
  border-radius: 4px;
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
  color: black;
  display: flex;
  align-items: center;
}

.menuItem:hover {
  background-color: #005da8;
  color: white;
  cursor: pointer;
}

.menuItem svg {
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
}

.menuItem:disabled svg,
.menuItem:hover svg {
  fill: currentcolor;
}

.menuItemActive {
  background-color: #e94d19;
  color: white;
  cursor: pointer;
}

.menuItemActive:hover {
  background-color: #808b94;
  color: white;
  cursor: pointer;
}

.mobileBtns {
  display: none;
}

.searchBox {
  flex-grow: 2;
  width: 300px;
  justify-self: flex-end;
  margin: 0 30px;
  height: 32px;
  padding: 8px;
}

.addNewDropdownBox {
  min-width: 200px;
  position: absolute;
  top: calc(16px + 25px);
  right: 0;
  z-index: 1000;
  padding: 16px 0;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;
}

.addNewDropdownBox > li {
  user-select: none;
  padding: 16px 20px;
  transition: background-color 0.5s ease-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addNewDropdownBox > li:hover {
  cursor: pointer;
  background-color: rgba(211, 211, 211, 0.2);
  color: #005da8;
}

.addNewDropdownBox > li p {
  /* color: rgb(97, 97, 97); */
  margin-left: 8px;
  margin-top: 2px;
  margin: -1px 0 0 8px;
}

.iconBtn {
  justify-self: flex-end;
  font-weight: 900;
  color: black;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  background-color: rgb(240, 240, 240);
  display: flex;
  padding: 6px 8px;
  border-radius: 4px;
  border: 2px solid #808b94;
  box-sizing: border-box;
}

.iconBtn svg {
  height: 20px;
  width: 20px;
  fill: currentcolor;
}

.iconBtn:hover {
  color: white;
  background-color: #005da8;
}

.iconBtn:hover > svg {
  fill: white;
}

.menuBox {
  min-width: 260px;
  position: absolute;
  top: calc(16px + 24px);
  right: 0;
  z-index: 2000;
  padding: 8px 0;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;
  text-align: center;
  overflow: auto;
  max-height: 83vh;
  font-weight: 500;
}

.menuBox > hr {
  border: 1px solid rgba(211, 211, 211, 0.5);
  margin: 8px 0;
}

.menuBox > li,
.menuBox > a {
  user-select: none;
  padding: 16px 20px;
  transition: background-color 0.5s ease-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 8px;
  border-radius: 4px;
}

.menuBox > li:hover {
  background-color: #005da8;
  color: white;
  cursor: pointer;
}

.menuBox > li p {
  /* color: rgb(97, 97, 97); */
  margin-left: 8px;
  margin-top: 2px;
  margin: -1px 0 0 8px;
}

.secondaryMenuBox {
  min-width: 200px;
  position: absolute;
  top: calc(16px + 24px);
  right: 0;
  z-index: 2000;
  padding: 8px 0;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;
}

.secondaryMenuBox > hr {
  border: 1px solid rgba(211, 211, 211, 0.5);
  margin: 8px 0;
}

.secondaryMenuBox > li,
.secondaryMenuBox > a {
  user-select: none;
  padding: 16px 20px;
  transition: background-color 0.25s ease-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.secondaryMenuBox > li:hover {
  cursor: pointer;
  background-color: rgba(211, 211, 211, 0.2);
  color: #005da8;
}

.secondaryMenuBox > li p {
  /* color: rgb(97, 97, 97); */
  margin-left: 8px;
  margin-top: 2px;
  margin: -1px 0 0 8px;
}

.showFilters {
  margin: 0 20px;
}

.settingsBox {
  min-width: 270px;
  position: absolute;
  top: calc(16px + 32px);
  right: 0;
  z-index: 2000;
  padding: 8px 0;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;
  text-align: center;
  overflow: auto;
  max-height: 83vh;
  font-weight: 500;
}

.settingsBox hr {
  border: 1px solid rgba(211, 211, 211, 0.5);
  margin: 8px 0;
}

.settingsBox li,
.settingsBox a {
  user-select: none;
  padding: 16px 20px;
  transition: background-color 0.5s ease-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 8px;
  border-radius: 4px;
}

.settingsBox .liLink {
  display: flex;
  padding: 0;
}

.settingsBox .liLink {
  display: flex;
  gap: 8px;
  padding: 0 20px;
  text-decoration: none;
  color: black;
}

.settingsBox .liLink:hover {
  text-decoration: none;
  color: #005da8;
  background-color: rgba(211, 211, 211, 0.2);
}

.settingsBox li:hover {
  background-color: #005da8;
  color: white;
  cursor: pointer;
}
.settingsBox li:hover > svg {
  fill: white;
}

.settingsBox li p {
  /* color: rgb(97, 97, 97); */
  margin-left: 8px;
  margin-top: 2px;
  margin: -1px 0 0 8px;
}

.addBtn {
  /* margin: 0 20px; */
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 8px;
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
  border-radius: 4px;
  border: 2px solid #808b94;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  max-height: 36px;
}

.addBtn:hover {
  color: white;
  border: 2px solid #005da8;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #005da8;
  color: white;
  cursor: pointer;
}

.addBtn:disabled svg,
.addBtn:hover svg {
  fill: currentcolor;
}

.addDropBtn {
  margin-right: 18px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 6px;
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
  border-radius: 4px;
  border: 2px solid #808b94;
  /* border-left: none; */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  max-height: 36px;
}

.addDropBtn:hover {
  color: white;
  border: 2px solid #005da8;
  /* border-left: none; */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #005da8;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.addDropBtn:disabled svg,
.addDropBtn:hover svg {
  fill: currentcolor;
}

.addDropBtnAllEntities {
  margin-right: 18px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  font-family: unset;
  padding: 4px 6px;
  transition: color 0.1s ease-out, background-color 0.25s ease-out;
  border-radius: 4px;
  border: 2px solid #808b94;
  display: flex;
  align-items: center;
  max-height: 36px;
}

.addDropBtnAllEntities:hover {
  color: white;
  border: 2px solid #005da8;
  background-color: #005da8;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.addDropBtnAllEntities:disabled svg,
.addDropBtnAllEntities:hover svg {
  fill: currentColor;
}

.addDropBtnAllEntities::before {
  content: " NEW ";
  padding: 0 8px;
}

@media screen and (max-width: 720px) {
  .sectionCenter {
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .secondaryMenuBox {
    width: -webkit-fill-available;
    position: fixed;
    top: 64px;
    margin: 0 1vw;
    max-height: 83vh;
    overflow: auto;
  }
}

@media screen and (max-width: 460px) {
  .logoContainer {
    margin-right: 6px;
  }
  .logo {
    font-size: 5vw;
  }
  .triangle {
    height: 5vw;
  }
  .sectionCenter {
    padding: 0;
  }
  .menuItem {
    margin: 0;
    /* padding: 0; */
  }
  .addBtn span {
    display: none;
  }
  .addDropBtn {
    margin-right: 4px;
  }
  .addDropBtnAllEntities {
    margin: 0;
    /* padding: 0; */
  }
  .addDropBtnAllEntities::before {
    content: " + ";
    font-size: 30px;
    margin-top: -6px;
    padding: 0;
  }
}
